import React, { Component } from 'react';
import './style.css';

export default class PrivacyPolicy extends Component {

  render() {

    return (
      <div className='privacyPolicy'>
        <div className='content'>
          <h2>Privacy Policy</h2>
          <br/>
          <p>PrestigeHomeUK® believe strongly in protecting the privacy of your data and the personally identifiable information you share with us, and respecting the permission you give us to use that information. This Privacy Policy notifies you of our privacy practices and describes how we will use your information, and describes the choices you have concerning how your information is collected and used. This will make it easier for you to do the following:</p>
          <p> ● Request information from the PrestigeHomeUK specific to your interests</p> 
          <p> ● Receive personalized emails to your specific questions</p> 
          <p> ● Save time by storing your preferences</p> 
          <p> ● Utilize web site-based services</p> 
          <p> ● Allow us to conduct market research to help us improve our products, our services and our web sites.</p> 
          <p>We will only collect, use or share your personally identifiable information if you give us permission to do so. Financial information provided by you is used solely to transact payments as you arrange through the use of Services provided by a particular web site.  For example, by making a payment, completing a quote form, sending us email or utilizing other services that may be available through our web site, you are giving us permission to use and share your non-financial information. You should know that by your quotation form, sending us email or other utilization of web site services you are giving PrestigeHomeUK permission to transfer your personally identifiable information to PrestigeHomeUK to fulfill your requirement.  However, we will not share any information you provide with any non-affiliated companies or body.</p>
         <p>We use commercially reasonable means to minimize the risk of unauthorized disclosures of your data and personally identifiable information, including restricting access to your data and personally identifiable information to those employees who need to know that information to provide and fulfill services to you.  We maintain appropriate physical, electronic, procedural, and managerial safeguards to help prevent unauthorized access to the information.  We may disclose information as required by law.</p>
         <p>In general, you can visit the PrestigeHomeUK web sites without telling us who you are or revealing any information about yourself. </p>
         <p>If you would like to contact us for any reason regarding our Privacy Policy you may do so via the "Contact" page on this website or by emailing to:</p>
         <p style={{fontWeight: '600'}}>prestigehomeuk@gmail.com</p>
         <p>We reserve the right to change this policy at any time by posting a new Privacy Policy.</p>
        </div>
      </div>
    );
  }
}
