import React, { Component } from 'react';
import './style.css';
import CEOImage from './ceoReady2.png';
import COOImage from './cooReady2.png';

export default class About extends Component {
  
  render() {

    return (
      <div className='aboutUs'>
        <div className='content'>
          <h2>About us</h2>
          <p>PrestigeHomeUK is a South East London building company that aims to deliver perfect solutions for competitive prices.</p>
          <p>{'Our number one goal is exceeding our customer\'s\ expectations. A lot of our work has been based on personal recommendations and working together with our customers.'}</p>
          <p>We offer a very wide range of services from the smallest interior finishings such painting jobs, as well as being able to take on entire house rebuilds that can last up to a year.</p>
        </div>
        <div className='workersContainer row text-center'>
          <div className='workersColumn col-md-6'>
            <h3>Tomasz Koscielski</h3>
            <h4>Founder</h4>
            <br/>
            <img src={CEOImage} class='img-responsive rounded-circle workerImage'/>
          </div>
          <div className='workersColumn col-md-6'>
            <h3>Sylwia Koscielska</h3>
            <h4>Operations</h4>
            <br/>
            <img src={COOImage} class='img-responsive rounded-circle workerImage'/>
          </div>

        </div>
      </div>
      
    );
  }
}
