import React, { Component } from 'react';
import '../style.css';
import sash from '../sash.png';
import flush from '../flush.png';
import storm from '../storm.png';

export default class TimberWindows extends Component {
  
  render() {

    return (
      <div className='timberWindows'>
        <div className='content'>
          <h2>Timber Windows</h2>
          <p>{'We\'\ ve been installing all kinds of windows for years. Our Timber Windows quality and prices is really hard to compete with in the UK market which has declined in this area in the recent years but we\'\ve made sure to stay on top of the game.'}</p>
          <p>{'Below are the three kinds of windows we sell. We give you a choice of ordering them directly or coming to you and having a professional install them. Both options require accurate measurements taken before placing the order. Please send us an email on prestigehomeuk@gmail.com if interested in the windows.'}</p>
        </div>
        <div className='windowsContainer row text-center'>
          <div className='windowsColumn col-md-4'>
            <h3>Sash</h3>
            <br/>
            <img src={sash} class='img-responsive windowsImage'/>
          </div>
          <div className='windowsColumn col-md-4'>
            <h3>Flush Casement</h3>
            <br/>
            <img src={flush} class='img-responsive windowsImage'/>
          </div>
          <div className='windowsColumn col-md-4'>
            <h3>Stormproof</h3>
            <br/>
            <img src={storm} class='img-responsive windowsImage'/>
          </div>
        </div>
      </div>
      
    );
  }
}
