import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import Img from './../prestigeHomeLogo.png';
import { Link } from 'react-scroll';

const Styles = styled.div`
  .navbar {
    background: linear-gradient(#606060, black);
    justify-content: center;
  }

  .navbar-brand, .navbar-nav, .nav-link, .nav-item {
    color: white !important;
    font-size: 18px;
    text-align: right;
    padding-right: .6rem;
    padding-left: .6rem;
  
    &:hover {
      color: orange !important;
    }

    @media only screen and (max-width: 600px) {
      width: 180px;
    }

  }
}
`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand='lg'>
      <Navbar.Brand href='/'><img style={{textAlign: 'right'}} src={Img}/></Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto'>
          <Nav.Item><Nav.Link href='/'>Home</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href='/windows'>Timber Windows</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href='/gallery'>Gallery</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href='/about'>About Us</Nav.Link></Nav.Item>
          <Link
            activeClass='active'
            to='contactUs'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}><Nav.Item><Nav.Link>Contact Us</Nav.Link></Nav.Item>
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
)
