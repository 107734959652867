import React, { Component } from 'react';
import { Container, Jumbotron, Row, Col, Image, Button } from 'react-bootstrap';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
import './style.css';

const images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));

function importAll(r) {
  return r.keys().map(r);
}

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      index: 0
    }
    
    this.incrementIndex = this.incrementIndex.bind(this);

  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState({index: this.state.index < 2 ? this.state.index + 1 : 0})
    }, 6000);
  }

  incrementIndex(i) {
    this.setState({index: i++});
  }

  render() {

    return (
      <Row className='mainHome'>
        <div className='content'>
          <h2>No jobs too big, No jobs too small.</h2>
          <p>Always aiming for the highest quality as well as your satisfaction! We've been established since 2013 and have expanded from the smallest interior jobs to entire house rebuilds including extensions. We simply get you want you want and go beyond with various recommendations that best fit your place.</p>
        </div>
        <div className='gallery'>
          <Gallery
            index={this.state.index}
            onRequestChange={i=> {
  	          this.incrementIndex(i);
            }}
          >
            {images.map(image => (
  	          <GalleryImage className='homeGallery' src={image} />
  	        ))}
          </Gallery>
        </div>
      </Row>
    );
  }
}
    
