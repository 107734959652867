import React, { Component } from 'react';
import './style.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, Card } from 'react-bootstrap';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function importAll(r) {
  let images = {
    bathroom: {},
    kitchen: {},
    extension: {},
    windows: {},
    other: {}
  };

  let decider = ''

  r.keys().map((item, index) => {

    
    if (item.includes('kitchens')) decider = 'kitchen'
    else if (item.includes('bathrooms')) decider = 'bathroom'
    else if (item.includes('extensions')) decider = 'extension'
    else if (item.includes('windows')) decider = 'windows'
    else decider = 'other'
    const identifier = item.split('/')[2];

    if (images[decider][identifier]) images[decider][identifier].push(r(item));
    else images[decider][identifier] = [r(item)]
    
    //images[item.replace('./', '')] = r(item);
  });
  
  return images;
}

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
};


export default class Gallery extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [[],[],[]],
      loading: false,
      instagramGallery: {
        kitchen: [],
        bathroom: [],
        extension: [],
        windows: [],
        other: []
      },
      filter: 'kitchen'
    }
  }

  setFilter(filter) {
    this.setState({filter: filter});
  }

  componentDidMount() {

    this.setState({loading: true});
    // this is old instagram scraper that works with 12 most recent posts only
    /* instagramScraper.getUserData('prestigehomeuk').then(posts => {
     *   posts.graphql.user.edge_owner_to_timeline_media.edges.map((item, index) => {
     *     //const caption = item.node.edge_media_to_caption.edges[0].node.text;
     *     const decider = 0; //caption.includes('bathroom') ? 0 : caption.includes('kitchen') ? 1 : 2;

     *     this.state.images[decider].push(
     *       <div className='col-md-4' key={index} >
     *         <a onClick={() => window.open('https://www.instagram.com/prestigehomeuk/') && window.open('https://www.instagram.com/p/'+ item.node.shortcode) } target='_blank'>
     *           <img className='galleryImage' src={item.node.thumbnail_src}/>
     *         </a>
     *       </div>
     *     );//TODO: clickable links to open the image or take to instagram
     *   });

     * }); */

    const galleryImages = importAll(require.context('./img/gallery/'))
    let decider;
    Object.keys(galleryImages).forEach((key, index) => {
      let decider = key;

      //const identifier = key.split('/');
      //const teller = identifier[1].match(/\d/g).join('');
      Object.keys(galleryImages[key]).forEach(area => {
        const slider = (
          <Slider {...settings}>
            {galleryImages[area.replace(/[0-9]/g, '')][area].map((detail, detInd) => {
              return (
                <React.Fragment key={detInd}>
                  <Card>
                    <LazyLoadImage
                      src={detail}
                    />
                  </Card>
                </React.Fragment>
              )
            })}
          </Slider>
        )
        this.state.instagramGallery[area.replace(/[0-9]/g, '')].push([slider]);

      })

      if (key === 'other') {
        this.setState({loading: false});
      }
      
    });

  }
  
  render() {

    if (this.state.loading === 'error') {
      return (
        <Container>
          <div className='clearfix mt-5 mb-2'>
            <h3 className='float-left' style={{color: 'red'}}>
              Error loading Gallery... please try again later
            </h3>
          </div>
        </Container>
      )
    } else if (this.state.loading || this.state.instagramGallery.length === 0) {
      return (
        <div className='loading'>
          <CircularProgress  />
        </div>
      );
    } else {

      return (
        <Container>
          <div className='clearfix mt-5 mb-2'>
            <h2>
              Gallery
            </h2>
            <br/>
            <div className='viewFilterContainer'>
              <div className='viewFilter' onClick={() => this.setState({filter: 'kitchen'})} style={this.state.filter === 'kitchen' ? {'background': 'orange'} : null}>
                <h5 className='filter'>
                  Kitchens
                </h5>
              </div>
              <div className='viewFilter' onClick={() => this.setState({filter: 'bathroom'})} style={this.state.filter === 'bathroom' ? {'background': 'orange'} : null}>
                <h5 className='filter'>
                  Bathrooms
                </h5>
              </div>
              <div className='viewFilter' onClick={() => this.setState({filter: 'extension'})} style={this.state.filter === 'extension' ? {'background': 'orange'} : null}>
                <h5 className='filter'>
                  Extensions
                </h5>
              </div>
              <div className='viewFilter' onClick={() => this.setState({filter: 'windows'})} style={this.state.filter === 'windows' ? {'background': 'orange'} : null}>
                <h5 className='filter'>
                  Windows
                </h5>
              </div>
              <div className='viewFilter' onClick={() => this.setState({filter: 'other'})} style={this.state.filter === 'other' ? {'background': 'orange'} : null}>
                <h5 className='filter'>
                  Other
                </h5>
              </div>
            </div>
          </div>
          
          {
            this.state.filter === 'kitchen' ? (
              this.state.instagramGallery.kitchen.map((kitchen) => {
                return (
                  <div className='galleryElement'>
                    {kitchen}
                  </div>
                )
              })) : null
          }
          {
            this.state.filter === 'bathroom' ? (
              this.state.instagramGallery.bathroom.map((bathroom) => {
                return (
                  <div className='galleryElement'>
                    {bathroom}
                  </div>
                )
              })) : null
          }
          {
            this.state.filter === 'extension' ? (
              this.state.instagramGallery.extension.map((extension) => {
                return (
                  <div className='galleryElement'>
                    {extension}
                  </div>
                )
            })) : null
          }
          {
            this.state.filter === 'windows' ? (
              this.state.instagramGallery.windows.map((window) => {
                return (
                  <div className='galleryElement'>
                    {window}
                  </div>
                )
            })) : null
          }
          {
            this.state.filter === 'other' ? (
              this.state.instagramGallery.other.map((other) => {
                return (
                  <div className='galleryElement'>
                    {other}
                  </div>
                )
            })) : null 
          }
        </Container>
      );
    }
  }
}  

/*
          <h3>{'Recent Posts from Instagram'}</h3>
          <h5 style={{color: 'orange', fontWeight: 600}}>{'Click on any of the images to see more pictures!'}</h5>
          <div class='row row-no-gutters instagramGallery'>
            {this.state.images[0]}
          </div>
*/



