import React, { Component } from 'react';
import Img from './../prestigeHomeLogo.png';

export default class Footer extends Component {

  render() {

    const date = new Date()
    const currentYear = date.getFullYear()

    return (
      <footer class="page-footer font-small" id="contactUs">
        <div class="container-fluid text-md-center">
          <div class="row text-md-center justify-content-center ">
            <div class="col-md-3 mb-md-0 mb-3 py-4 footerLogo">
              <h5 class="text-uppercase"></h5>
              <ul class="list-unstyled">
                <li>
                </li>
                <li>
                  <a class="navbar-brand"><img src={Img}/></a>
                </li>
                <li>
                </li>
              </ul>
            </div>
            <div class="col-md-3 mb-md-0 mb-3 py-4 addressFooter">
              <h5 class="text-uppercase">CONTACT US</h5>
              <ul class="list-unstyled">
                <li>
                  <a>Orpington</a>
                </li>
                <li>
                  <a>KENT</a>
                </li>
                <li>
                  <a>BR6 9BG</a>
                </li>
              </ul>
            </div>
            <div class="col-md-3 mb-md-0 mb-3 py-4 emailPhone">
              <h5 class="text-uppercase">EMAIL / PHONE</h5>
              <ul class="list-unstyled">
                <li>
                  <a>prestigehomeuk@gmail.com</a>
                </li>
                <li>
                  <a>01689873564</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-copyright text-center copyright">{'© ' + currentYear + ' Copyright'}
        </div>
        <div class="footer-cookieConent text-center copyrightContent">By using prestigehomeuk.co.uk, you agree with our use of cookies to improve performance and enhance your user experience.
          <a class="underline" href="/privacyPolicy" title="View our Privacy Policy.">View our Privacy Policy.</a>
        </div>
      </footer>
    );

  }

}
